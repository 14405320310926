<script>
import AppAsideSubMenu from '@/components/AppAsideSubMenu'
import AppAsideSingleItem from '@/components/AppAsideSingleItem'
// this.$parent.$el.dispatchEvent(new MouseEvent('mouseenter'));
// issure 在导航为collapse状态时候，导航组件会递归广播mouseenter事件，从而导致调用栈溢出报错
export default {
  name: 'AppAsideSmartMenu',
  functional: true,
  render(createElement, { props, data, children, parent }) {
    const { item } = props
    if (item.items && item.items.length > 0) {
      data.props = props
      return parent.$createElement(AppAsideSubMenu, data, children)
    }
    return parent.$createElement(AppAsideSingleItem, data, children)
  }
}
</script>
