export function formatNumer(n) {
  return n > 9 ? n : '0' + n
}

export function formatTime(seconds) {
  return formatNumer(Math.floor(seconds / 60)) + ':' + formatNumer(seconds % 60)
}

export function setStorage(key, value, type = 'localStorage') {
  window[type].setItem(
    key,
    typeof value === 'object' ? JSON.stringify(value) : value
  )
}

export function getStorage(key, type = 'localStorage') {
  var value = window[type].getItem(key)
  if (value) {
    try {
      return JSON.parse(value)
    } catch (error) {
      return value
    }
  }
  return value
}

export function copy(obj) {
  return typeof obj === 'object' ? JSON.parse(JSON.stringify(obj)) : obj
}

export function setSessionStorage(key, value) {
  setStorage(key, value, 'sessionStorage')
}

export function getSessionStorage(key) {
  return getStorage(key, 'sessionStorage')
}

export function clearSessionStorage() {
  window.sessionStorage.clear()
}

export function urlQueryString(url, params) {
  let querys = []
  for (let key in params) {
    let value = params[key]
    if (value !== undefined && value !== null) querys.push(`${key}=${value}`)
  }
  return url + (querys.length ? '?' + querys.join('&') : '')
}

/** 判断对象是否为空(有key，但是对应的val都为空，该对象为空)
 * 数组对象(里面最少有一项是有效值，非null/underfined)
 * 对于非传递的非对象，非数组，都返回true
 * @param  {} param
 */
export function isEmpty(param) {
  if (param instanceof Object) {
    if (Array.isArray(param)) {
      var filterParam = param.filter(function(item) {
        return !!item || item === 0
      })
      return filterParam.length === 0
    } else {
      for (var key in param) {
        if (!!param[key] || param[key] === 0) {
          return false
        }
      }
      return true
    }
  }
  return false
}

/**
 *
 * @param {Array,String,Object} needParams
 * @param {*} paramsMap
 */
export function buildParamsUrl(needParams, paramsMap) {
  if (Array.isArray(needParams)) {
    if (needParams.length === 0) return ''
    return needParams.reduce((total, cur) => {
      return total + (total.length ? '&' : '') + `${cur}=${paramsMap[cur]}`
    })
  } else if (needParams.length) {
    return `${needParams}=${paramsMap[needParams]}`
  } else {
    let keys = Object.keys(needParams)
    return buildParamsUrl(keys, paramsMap)
  }
}

export function appendUrl(url, appenUrl) {
  if (appenUrl) {
    return url + (url.indexOf('?') === -1 ? '?' : '&') + appenUrl
  } else {
    return url
  }
}

export const groupBy = (list, fn) => {
  const groups = {}
  list.forEach(item => {
    const key = JSON.stringify(fn(item))
    groups[key] = groups[key] || []
    groups[key].push(item)
  })

  return groups
}

// 格式化服务器时间戳
// 年月日时分秒必须分别按照YYYY、MM、DD、HH、mm、ss
export const formatUnixTime = (unixTime, format = 'YYYY-MM-DD HH:mm:ss') => {
  const date = new Date(unixTime * 1000) // 后端时间戳为10位需*1000
  const YYYY = date.getFullYear()
  const month = date.getMonth() + 1 // date.getMonth()取值范围0 ~ 11
  const MM = month < 10 ? '0' + month : month
  const day = date.getDate()
  const DD = day < 10 ? '0' + day : day
  const hour = date.getHours()
  const HH = hour < 10 ? '0' + hour : hour
  const minute = date.getMinutes()
  const mm = minute < 10 ? '0' + minute : minute
  const second = date.getSeconds()
  const ss = second < 10 ? '0' + second : second
  const obj = { YYYY, MM, DD, HH, mm, ss }

  Object.keys(obj).forEach(key => {
    format = format.replace(new RegExp(key, 'g'), obj[key])
  })

  return format
}

// 获取浏览器窗口的可视区域的高度
export const getViewPortHeight = () => {
  return document.documentElement.clientHeight || document.body.clientHeight
}

/**
 * 从一组数据中提取某些值
 * @param {Array} rowDatas [{age:1,name:'A'},{age:2,name:'B'},{age:3,name:'C'}]
 * @param {Array} paramKeys ['age','name']
 *
 * @returns {age:[1,2,3],name:['A','B','C']}
 */
export function extractParams(rowDatas, paramKeys) {
  let params = {}
  rowDatas.forEach(data => {
    paramKeys.forEach(key => {
      if (params[key] === undefined) {
        params[key] = []
      }
      if (data[key] !== undefined) {
        params[key].push(data[key])
      }
    })
  })
  return params
}

export function converFileSize(limit) {
  var size = ''
  if (limit < 0.1 * 1024) {
    //如果小于0.1KB转化成B
    size = limit.toFixed(2) + 'B'
  } else if (limit < 0.1 * 1024 * 1024) {
    //如果小于0.1MB转化成KB
    size = (limit / 1024).toFixed(2) + 'KB'
  } else if (limit < 0.1 * 1024 * 1024 * 1024) {
    //如果小于0.1GB转化成MB
    size = (limit / (1024 * 1024)).toFixed(2) + 'MB'
  } else {
    //其他转化成GB
    size = (limit / (1024 * 1024 * 1024)).toFixed(2) + 'GB'
  }

  var sizestr = size + ''
  var len = sizestr.indexOf('.')
  var dec = sizestr.substr(len + 1, 2)
  if (dec == '00') {
    //当小数点后为00时 去掉小数部分
    return sizestr.substring(0, len) + sizestr.substr(len + 3, 2)
  }
  return sizestr
}

export function getInfoFromMeta(key) {
  const mateValue = document.head.getElementsByTagName('meta')[key]
  if (mateValue) {
    return mateValue.getAttribute('content')
  }
}