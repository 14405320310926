<template>
  <div>
    <div class="xa-cell" v-show="!collapse">
      <el-avatar
        class="xa-mgr-8"
        icon="el-icon-user-solid"
        :src="info.avatar_thumb || defaultImg"
      ></el-avatar>
      <el-dropdown size="small" @command="$emit('command', $event)">
        <div class="xa-cell user-info__wrap">
          <div class="user-info__content">
            <div class="user-info__name xa-txt-bold xa-txt-ellipsis">
              {{ info.name }}
            </div>
            <div class="user-info__name xa-txt-ellipsis xa-txt-12">
              {{ info.role }}
            </div>
          </div>
          <i class="el-icon-caret-bottom el-icon--right"></i>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>
            <AppLanguage :placement="collapse ? 'top' : 'right'" />
          </el-dropdown-item>
          <el-dropdown-item command="logout" class="xa-txt-red">{{
            $t('Logout')
          }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div v-show="collapse">
      <el-popover
        placement="right"
        width="200"
        trigger="hover"
        popper-class="user-info__popper"
      >
        <div class="xa-cell user-info__btn-wrap">
          <span class="user-info__name xa-txt-ellipsis">{{ info.name }}</span>
          <AppLanguage />
          <span
            class="user-info__btn xa-txt-red"
            @click="$emit('command', 'logout')"
            >退出</span
          >
        </div>
        <img
          slot="reference"
          class="user-info__img"
          :src="info.avatar_thumb || defaultImg"
          alt="用户头像"
        />
      </el-popover>
    </div>
  </div>
</template>
<script>
import AppLanguage from '@/components/AppLanguage'
import defaultImg from '@/assets/default_person.png'
export default {
  components: {
    AppLanguage
  },
  data() {
    return {
      defaultImg
    }
  },
  props: {
    collapse: Boolean,
    info: Object
  }
}
</script>
<style lang="scss">
.user-info__wrap {
  overflow: hidden;
  color: #fff;
  cursor: pointer;
  .el-icon--right {
    color: #fff;
  }
}
.user-info__name {
  max-width: 6em;
}
.user-info__btn-wrap {
  justify-content: space-between;
}
.user-info__btn {
  cursor: pointer;
}
.user-info__content {
  line-height: 1.4;
}
.user-info__img {
  $size: 40px;
  display: block;
  align-self: center;
  width: $size;
  height: $size;
  border-radius: 50%;
  border: 1px solid #888;
  margin-right: 5px;
  object-fit: cover;
}
.user-info__popper {
  padding: 0;
  transform: translateX(4px);
}
</style>
