// 全局注册
<template>
  <div class="wrap">
    <div class="line"></div>
  </div>
</template>
<script>
export default {
  name: 'AppLineLoading'
}
</script>
<style lang="scss" scoped>
@keyframes loading {
  0% {
    left: 0;
    width: 2%;
  }
  40% {
    left: 50%;
    width: 20%;
  }
  100% {
    left: 120%;
    background-color: lighten($color-green, 10%);
    width: 15%;
  }
}

.wrap {
  overflow: hidden;
  width: 100%;
  height: 4px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.line {
  height: 4px;
  width: 20px;
  position: absolute;
  left: 0;
  top: 0;
}

.line {
  animation: 2.5s loading cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite forwards
    normal;
}
</style>
