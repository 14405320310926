class DownLoad {
  constructor() {
    this._id = 1
    this.tasks = []
    this.listens = {}
  }
  on(type, fn) {
    const listens = this.listens[type] || (this.listens[type] = [])
    listens.push(fn)
  }
  fire(type, ...arg) {
    let listens = Array.from(this.listens[type] || [])
    if (listens.length) {
      listens.forEach(fn => {
        fn.apply(null, arg)
      })
    }
  }
  add(task, { title, total }) {
    const id = this._id++
    this.fire('add', {
      id,
      title,
      total,
      percentage: 0
    })
    this.tasks.push({
      id,
      title,
      total,
      task
    })
    task.on('load', cur => {
      this.fire('load', {
        id,
        title,
        total,
        percentage: parseInt((cur / total) * 100)
      })
    })
    task.on('finish', () => {
      const index = this.tasks.findIndex(item => item.id === id)
      this.tasks.splice(index, 1)
      this.fire('finish', {
        id,
        title,
        total,
        percentage: 100
      })
    })
    return ()=> this._remove(id)
  }
  _remove(id, percentage) {
    const index = this.tasks.findIndex(item => item.id === id)
      this.tasks.splice(index, 1)
      this.fire('finish', {
        id,
        percentage: percentage
      })
  }
}

export default new DownLoad()
