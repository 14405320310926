/**
 * 获取默认的app
 * @param {String|undefined} initIndex
 * @param {Object[]} apps
 * @param {String} apps[].index
 * @param {1|0} apps[].default
 */
export function getDefaultAppIndex(initIndex = '', apps = []) {
  let appIndex
  if (initIndex) {
    appIndex = apps.findIndex(appItem => appItem.index === initIndex)
  } else {
    appIndex = apps.findIndex(appItem => !!appItem.default)
  }
  return appIndex === -1 ? 0 : appIndex
}
