import { getStorage, setStorage } from './index'
import { SetLanguage } from '@target/apis/user'
const STORAGE_KEY = 'agir-bi_USER_LANGUAGE'

function getNavigatorLang() {
  const lang = navigator.userLanguage || window.navigator.language
  return lang.toLowerCase()
}

function getSettingLanguage() {
  return getStorage(STORAGE_KEY)
}

export function getDefaultLanguage() {
  return getSettingLanguage() || getNavigatorLang()
}

export function setDefaultLanguage(lang) {
  setStorage(STORAGE_KEY, lang)
}

const renameLangMap = {
  zh: 'zh-cn',
  'ja-jp': 'ja'
}
export async function changeAppLanguage(lang, oldLang) {
  lang = renameLangMap[lang] || lang
  if (lang === oldLang) return
  setDefaultLanguage(lang)
  await SetLanguage(lang)
  window.location.reload()
}
// 可以选择的语言
export const choseLanguages = JSON.parse(
  process.env.VUE_APP_I18N_LOCALE_SUPPORT.replace(/'/g, '"')
)
// 分析显示与值的对应关系
export const choseLanguagesMap = choseLanguages.reduce(
  (total, item) => (total[item[0]] = item[1]) && total,
  {}
)
