export default [
  {
    path: 'mallcategory',
    name: 'mallcategory',
    component: () => import('@target/views/mall/category')
  },
  {
    path: 'skuForm',
    name: 'skuForm',
    component: () => import('@target/views/mall/skuForm.vue')
  },
  {
    path: 'mallactivity',
    name: 'mallactivity',
    component: () => import('@target/views/mall/mallActivity')
  },
  {
    path: 'productform',
    name: 'productform',
    component: () => import('@target/views/mall/productForm.vue')
  }
]
