<template>
  <el-menu
    class="aside__el-menu"
    background-color="#1f2d3d"
    text-color="#fff"
    active-text-color="#366eb4"
    :collapse="isCollapse"
    :unique-opened="defaultOpeneds.length === 0"
    :default-active="defaultActive"
    :default-openeds="defaultOpeneds"
    @select="handleSelect"
  >
    <template v-for="mItem in nav">
      <AppAsideSmartMenu
        :item="mItem"
        :key="mItem.index"
        :index="mItem.index"
        :keywork="keywork"
      />
    </template>
  </el-menu>
</template>
<script>
import AppAsideSmartMenu from '@/components/AppAsideSmartMenu'
export default {
  components: {
    AppAsideSmartMenu
  },
  props: {
    nav: Array,
    defaultActive: String,
    defaultOpeneds: {
      type: Array,
      default() {
        return []
      }
    },
    isCollapse: Boolean,
    keywork: String
  },
  methods: {
    handleSelect(key, keyPath) {
      this.$emit('change', key, keyPath)
    }
  }
}
</script>
