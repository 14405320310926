import coupon from './coupon'
import mall from './mall'
import school from './school'
export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@target/views/Login')
  },
  ...coupon,
  ...mall,
  ...school
  // /**
  //  * 优惠券
  //  *  :type  create 创建优惠券
  //  *  :create 2 创建单品优惠券
  //  *
  //  */
  // {
  //   path: 'coupon/form/:action/:type',
  //   name: '',
  //   component: () => import('@target/views/coupon/form/Base.vue')
  // }
]
