import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './plugins/xa.js'
import i18n from './i18n'
import './styles/global.scss'

Vue.config.productionTip = false
Vue.prototype.$ELEMENT = { size: 'small' }
Vue.config.lang = i18n.locale // 这个变量，将在 ./plugins/element.js 用来翻译

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
