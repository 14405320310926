<template>
  <div class="xa-flex-center" v-if="progress.length">
    
      <el-popover placement="right" width="240" trigger="hover">
        <div>
          <div v-for="item in progress" :key="item.id">
            <div class="xa-txt-12" style="margin:4px 0;">
              {{ item.title }}({{ item.total }}条)
            </div>
            <el-progress
              :text-inside="true"
              :stroke-width="16"
              :percentage="item.percentage"
            ></el-progress>
          </div>
        </div>
        <div slot="reference" class="xa-flex-center">
          <div
            v-loading="1"
            class="xa-txt-white xa-flex-center"
            element-loading-background="rgba(0, 0, 0, 0)"
            style="width:50px;height:50px"
          >
            <i class="el-icon-download xa-txt-18"></i>
          </div>
        </div>
      </el-popover>
  </div>
</template>
<script>
import AppDownLoadProgress from '@/controllers/AppDownLoadProgress'
export default {
  data() {
    return {
      progress: [] // {id,title,total,percentage}
    }
  },
  mounted() {
    AppDownLoadProgress.on('add', newItem => this.progress.push(newItem))
    AppDownLoadProgress.on('load', newItem =>
      this.progress.forEach(item => {
        if (item.id === newItem.id) {
          item.percentage = newItem.percentage
        }
      })
    )
    AppDownLoadProgress.on('finish', newItem => {
      const index = this.progress.findIndex(item => item.id === newItem.id)
      this.progress.splice(index, 1)
    })
  }
}
</script>
<style lang="scss" scoped></style>
