import { get } from './index'
export const CheckLogin = get(process.env.VUE_APP_API_USER_CHECK)
export const Logout = get(process.env.VUE_APP_API_USER_LOGOUT)

/**
 * @returns {Object} result
 * @returns {Object[]} result.t_nav
 * @returns {String} Object[].title // 名称
 * @returns {String} Object[].icon  // 图标
 * @returns {String} Object[].url   // 所处的页面地址
 * @returns {String} Object[].index // 用于路由 /home/:index 中的index参数
 * @returns {String} Object[].icon  // 图标
 * @returns {String} Object[].api   // 后续菜单请求的接口
 * @returns {1|0} Object[].default
 */
export const GetMainMenu = get(process.env.VUE_APP_API_MAIN_MENU)

export const SetLanguage = () => Promise.resolve()
