<template>
  <section class="skeleton-home-page">
    <div class="aside-panel">
      <div class="logo-box"></div>
      <div
        v-for="n in 16"
        class="nav-item skeleton-fade"
        :style="'--delay:0.' + n + 's'"
        :key="n"
      ></div>
    </div>
    <Welcome></Welcome>
  </section>
</template>
<script>
export default {
  components: {
    Welcome: () => import('@/components/Welcome.vue')
  },
  props: {
    title: {
      type: String,
      default: '概览'
    },
    tip: {
      type: String,
      default: ''
    }
  }
}
</script>
<style lang="scss" scoped>
.skeleton-home-page {
  width: 100%;
  height: 100%;
}
.aside-panel {
  float: left;
  overflow: hidden;
  width: 200px;
  height: 100%;
  background-color: $sider-bg;
  .logo-box {
    box-sizing: border-box;
    height: 32px;
    width: calc(100% - 40px);
    margin: 4px 20px;
    background-image: url(../../assets/logo.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
  .nav-item {
    height: 24px;
    width: calc(100% - 40px);
    margin: 20px;
  }
}
.wrap-box__wrap {
  border-radius: 8px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 0px 0.5px rgba(0, 0, 0, 0.04);
  font-family: 'PingFang SC';
  & + & {
    margin-top: 12px;
  }
  color: transparent;
}
.wrap-box__head {
  padding: 0 12px;
  height: 48px;
  &__title {
    font-size: 16px;
    font-weight: 500;
    width: min-content;
    white-space: nowrap;
  }
  &__tip {
    font-size: 12px;
    font-weight: 400;
    width: min-content;
    white-space: nowrap;
  }
}

.skeleton-fade {
  width: 100%;
  background: linear-gradient(to right, #ececf0 8%, #d3d3d9 18%, #ececf0 33%);
  background-size: 800px 45px;
  animation: shine 3s infinite;
  animation-delay: var(--delay, 0.3s);
}
.skeleton-text {
  color: transparent;
  white-space: nowrap;
  color: transparent;
}
@keyframes shine {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
</style>
