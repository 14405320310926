<template>
  <div class="app-lang">
    <el-popover
      :placement="placement"
      width="30"
      trigger="hover"
      :popper-class="'app-lang-popper ' + placement"
    >
      <div>
        <div
          v-for="(item, index) in langs"
          :key="index"
          class="xa-cursor app-lang__item"
          @click="onCommand(item[0])"
        >
          {{ item[1] }}
        </div>
      </div>
      <div slot="reference" class="xa-cursor">
        {{ langMap[$i18n.locale] }}
      </div>
    </el-popover>
  </div>
</template>
<script>
import {
  changeAppLanguage,
  choseLanguages,
  choseLanguagesMap
} from '@/utils/lang'
export default {
  data() {
    return {
      langs: choseLanguages,
      langMap: choseLanguagesMap
    }
  },
  props: {
    placement: {
      type: String,
      default: 'top'
    }
  },
  methods: {
    onCommand(command) {
      if (command !== this.$i18n.locale) {
        changeAppLanguage(command)
      }
    }
  }
}
</script>
<style lang="scss">
.app-lang-popper {
  padding: 4px;
  min-width: 100px;
  &.right {
    transform: translateX(10px);
  }
  &.top {
    transform: translateY(-4px);
  }
  .xa-cursor.app-lang__item {
    padding: 4px 0;
  }
}
</style>
