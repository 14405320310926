import Vue from 'vue'
const utils = require('@/utils/index')
import request from '@/apis/index'

import AppLineLoading from '@/components/AppLineLoading'

// 当前系统部署的地址
const curSysPublicPath = window.location.origin + window.location.pathname
Vue.use({
  // eslint-disable-next-line no-unused-vars
  install(Vue, options) {
    Vue.component(AppLineLoading.name, AppLineLoading)
    Vue.prototype.$utils = utils
    /**
     * 跳转到外链
     */
    Vue.prototype.$gotoUrl = function(url) {
      if (!url) return
      let newTab = false
      /** */
      if (url.indexOf(curSysPublicPath) === -1) newTab = true
      if (url.indexOf('./#/') === 0) newTab = false
      if (url.indexOf(window.location.pathname) === 0) newTab = false
      if (!newTab) {
        window.location.href = url
      } else {
        window.open(url)
      }
    }
    Vue.prototype.$diyAction = function(url, data = {}, type = 'get') {
      return request({
        url,
        data,
        method: type
      })
    }
    Vue.prototype.$actionWithConfirm = function(action) {
      if (action instanceof Promise) {
        return action.catch(error => {
          return new Promise((resolve, reject) => {
            this.$confirm(error.msg || error.message, '提示', {
              showCancelButton: false,
              confirmButtonText: '确定',
              type: 'error'
            }).then(() => {
              reject(error)
            })
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          this.$diyAction(...arguments)
            .then(data => resolve(data))
            .catch(error =>
              this.$confirm(error.msg || error.message, '提示', {
                showCancelButton: false,
                confirmButtonText: '确定',
                type: 'error'
              }).then(() => {
                reject(error)
              })
            )
        })
      }
    }
    Vue.prototype.$actionByRoute = async function(route = this.$route) {
      let params = route.query.params ? JSON.parse(route.query.params) : {}
      if (Array.isArray(params)) params = {}
      const src = route.query.src
      try {
        return await this.$diyAction(src, params)
      } catch (error) {
        let msg = error.msg || error.message
        if (error.status === 4300) {
          msg = '您没有相关权限！'
        }
        this.$confirm(msg, '提示', {
          showCancelButton: false,
          confirmButtonText: '确定',
          type: 'error'
        })
      }
    }
  }
})
