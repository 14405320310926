<template>
  <el-menu-item :index="index">
    <i class="iconfont" :class="item.icon" v-show="item._deep < 2"></i>
    <span role="highlight" v-html="calcTitle(item)"></span>
  </el-menu-item>
</template>
<script>
export default {
  props: {
    keywork: String,
    item: Object,
    index: String
  },
  methods: {
    calcTitle(item) {
      if (!this.keywork) return item.title
      const title = item.title
      const index = title.indexOf(this.keywork)
      if (index > -1) {
        return (
          title.substring(0, index) +
          `<span class="xa-txt-green" role="heigthlight">${this.keywork}</span>` +
          title.substring(index + this.keywork.length)
        )
      } else {
        return title
      }
    }
  }
}
</script>
