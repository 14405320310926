import Vue from 'vue'
import axios from 'axios'
import qs from 'qs'
import Router from '@/router/index'

const gloableHeaderConfig = {}
const redirectToLoginStatus = [8888, 4304, 401, 403, 4303]
const instance = axios.create({
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    token: '',
  },
})
/**
 * 后台对应的语言字典是
 * en/zh/ko-kr/ja-jp
 */
const langMap = {
  ja: 'ja-jp',
  ko: 'ko-kr',
}
instance.interceptors.request.use(
  function(config) {
    const lang = Vue.config.lang.split('-')[0]
    const commonData = {
      fromUrl: window.location.href,
      lang: langMap[lang] || lang,
    }
    // Do something before request is sent
    if (config.method.toLowerCase() === 'get') {
      // 如果是get请求，但是配置了data参数，此时应该设置为params参数
      config.params = config.params || config.data || {}
      Object.assign(config.params, commonData)
    } else if (config.method.toLowerCase() === 'post') {
      Object.assign(config.data || {}, commonData)
      if (config.headers['Content-type'] !== 'application/json') {
        config.data = qs.stringify(config.data)
      }
    }
    Object.assign(config.headers, gloableHeaderConfig)
    return config
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// 嗅探是不是返回的body里面，再使用status,message,data包裹一层的接口
function isEmbeddedData(data) {
  return 'status' in data && 'message' in data && 'data' in data
}

function getResponseData(data) {
  return isEmbeddedData(data) ? data.data : data
}

function getErrorResponse(data) {
  if ('status' in data && parseInt(data.status) !== 200) {
    if (redirectToLoginStatus.includes(data.status)) {
      window.location.href = data.data
    } else {
      handleErrorResponse(data)
    }
  }
  return data
}

function handleErrorResponse(data) {
  throw data
}

instance.interceptors.response.use(
  function(response) {
    if (typeof response.data === 'string') return response.data
    getErrorResponse(response.data)
    return getResponseData(response.data)
  },
  function(error) { // 处理状态非200
    // 解决错误信息为空的情况
    error.response.data = error.response.data || {
      message: `${error.response.status} : ${error.response.statusText}`,
    }
    const { status, data } = error.response
    if (redirectToLoginStatus.includes(status)) {
      if(data.data) {
        window.location.href = data.data
      } else {
        Router.replace({
          name: 'login'
        })
      }
      return new Promise(() =>{})
    }
    return Promise.reject(error.response.data)
  }
)
export function setGlobelHeaderByUrl(url, target) {
  const matchReg = new RegExp(`[&|/?]${target}=[\\w]+`)
  const replaceReg = new RegExp(`[&|/?]${target}=`)
  let targetStr = url.match(matchReg)
  if (targetStr) {
    let targetValue = targetStr[0]
    targetValue = targetValue.replace(replaceReg, '')
    if (targetValue.length > 24) {
      Object.assign(gloableHeaderConfig, {
        [target]: targetValue,
      })
    }
  }
}
const CacheStore = {}
function getStoreKey(url, params) {
  return url + JSON.stringify(params)
}
export const cacheGet = function(url) {
  return function(params = {}, cfg = {}) {
    const storeKey = getStoreKey(url, params)
    if (CacheStore[storeKey]) return Promise.resolve(CacheStore[storeKey])
    return instance({
      ...cfg,
      url,
      method: 'get',
      data: params,
    }).then((data) => {
      CacheStore[storeKey] = data
      return data
    })
  }
}
export const get = function(url) {
  return function(params = {}, cfg = {}) {
    return instance({
      ...cfg,
      url,
      method: 'get',
      data: params,
    })
  }
}
export const post = function(url) {
  return function(params, cfg = {}) {
    return instance({
      ...cfg,
      url,
      method: 'post',
      data: params,
    })
  }
}
export default instance
