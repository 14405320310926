import Vue from 'vue'
import VueRouter from 'vue-router'
import homeChildren from './home'
import Home from '../views/Home.vue'
import platformIndexRouter from '@target/router/index' // 不同平台的代码模块
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '',
    component: Home
  },
  ...platformIndexRouter,
  {
    path: '/diyForm',
    name: 'diyForm',
    component: () => import('@/views/diyForm/Index.vue')
  },
  {
    path: '/home',
    component: Home
  },
  {
    path: '/home/:index',
    component: Home,
    children: homeChildren
  }
]

const router = new VueRouter({
  routes
})

export default router
