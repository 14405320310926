const Components_Map = {
    '1': () => import('@target/views/coupon/form/CreateManJian.vue'),
    '2': () => import('@target/views/coupon/form/CreateDanPin.vue'),
    '3': () => import('@target/views/coupon/form/CreateZheKou.vue'),
    foot: () => import('@target/views/coupon/form/foot/Footer.vue')
  }
  export default [
    {
      path: 'coupon/form',
      name: '',
      component: () => import('@target/views/coupon/form/BaseRouter.vue'),
      children: [
        {
          path: '',
          components: {
            default: () => import('@target/views/coupon/Index.vue')
          }
        },
        {
          path: 'result',
          name: 'coupon-result',
          components: {
            default: () => import('@target/views/coupon/form/Result.vue')
          }
        },
        {
          path: 'distribution/batch',
          components: {
            default: () =>
              import('@target/views/coupon/form/DistributionBatch.vue'),
            foot: Components_Map['foot']
          }
        },
        {
          path: 'create/1',
          name: 'coupon-create-1',
          components: {
            default: Components_Map[1],
            foot: Components_Map['foot']
          }
        },
        {
          path: 'create/2',
          name: 'coupon-create-2',
          components: {
            default: Components_Map[2],
            foot: Components_Map['foot']
          }
        },
        {
          path: 'create/3',
          name: 'coupon-create-3',
          components: {
            default: Components_Map[3],
            foot: Components_Map['foot']
          }
        },
        {
          path: 'copy/1',
          name: 'coupon-copy-1',
          components: {
            default: Components_Map[1],
            foot: Components_Map['foot']
          }
        },
        {
          path: 'copy/2',
          name: 'coupon-copy-2',
          components: {
            default: Components_Map[2],
            foot: Components_Map['foot']
          }
        },
        {
          path: 'copy/3',
          name: 'coupon-copy-3',
          component: {
            default: Components_Map[3],
            foot: Components_Map['foot']
          }
        }
      ]
    }
    // /**
    //  * 优惠券
    //  *  :type  create 创建优惠券
    //  *  :create 2 创建单品优惠券
    //  *
    //  */
    // {
    //   path: 'coupon/form/:action/:type',
    //   name: '',
    //   component: () => import('@target/views/coupon/form/Base.vue')
    // }
  ]
  