<template>
  <div class="app-switch-apps" v-if="apps.length && index > -1">
    <el-popover
      placement="right-start"
      trigger="hover"
      :visible-arrow="false"
      popper-class="switch-apps__popper"
    >
      <div class="xa-view">
        <div
          v-for="(item, mIndex) in apps"
          :key="mIndex"
          class="switch-apps__item"
          :class="{ active: mIndex === index }"
          @click="changeApp(mIndex)"
        >
          <i class="iconfont" :class="item.icon"></i>
          {{ item.title }}
        </div>
      </div>
      <div class="switch-apps__box xa-cell xa-cursor" slot="reference">
        <i class="el-icon-s-grid xa-txt-24"></i>
        <div class="xa-flex" v-show="!isCollapse" style="margin-left:6px">
          <div class="xa-txt-ellipsis">{{ apps[index].title }}</div>
        </div>
        <slot name="icon-right">
          <i class="el-icon-arrow-right" v-show="!isCollapse"></i>
        </slot>
      </div>
    </el-popover>
  </div>
</template>
<script>
export default {
  props: {
    isCollapse: Boolean,
    apps: Array,
    index: [Number, String]
  },
  methods: {
    changeApp(index) {
      if (this.index !== index) this.$emit('change', index)
    }
  }
}
</script>
<style lang="scss">
.app-switch-apps {
  .switch-apps__box {
    padding: 10px 20px;
    color: #fff;
    cursor: pointer;
  }
}
.switch-apps__popper {
  max-height: calc(100vh - 40px);
  overflow: auto;
  padding: 0 !important;
  transform: translateX(-10px);
  background: $color-dark-color !important;
  user-select: none;
}
.switch-apps__item {
  padding: 0px 12px;
  line-height: 44px;
  font-size: 15px;
  color: #fff;
  cursor: pointer;
  &.active {
    color: $color-blue;
  }
  &:hover {
    color: $color-hover;
    background: $color-hover--dark;
  }
}
</style>
