<template>
  <el-container class="xa-container">
    <template v-if="!isLogging">
      <XaAside />
      <el-container direction="vertical" class="home-main__wrap">
        <el-main class="home-main__container">
          <keep-alive include="diyDataTable">
            <router-view></router-view>
          </keep-alive>
        </el-main>
      </el-container>
    </template>
    <SkeletonHome v-else />
  </el-container>
</template>

<script>
import XaAside from '@/components/layer/aside.vue'
import SkeletonHome from '@/components/skeleton/SkeletonHome.vue'
import { mapActions, mapMutations } from 'vuex'
import { CheckLogin } from '@/apis/user'
import { changeAppLanguage } from '@/utils/lang'
import { setTimeout } from 'timers'
export default {
  name: 'home',
  components: {
    XaAside,
    SkeletonHome
  },
  data () {
    return {
      isLogging: true
    }
  },
  methods: {
    ...mapMutations({
      changeWinSize: 'CHANGE_WINDOW_SIZE'
    }),
    ...mapActions({
      setUserInfo: 'USER_INFO_RESET'
    }),
    /**
     * 监听resize事件
     * 并在合适的时候通知changeWinSize=>vuex:CHANGE_WINDOW_SIZE方法
     */
    watchSizeChange () {
      window.addEventListener('resize', resizeThrottler, false)
      var resizeTimeout
      function resizeThrottler () {
        if (!resizeTimeout) {
          resizeTimeout = setTimeout(function () {
            resizeTimeout = null
            actualResizeHandler()
            // The actualResizeHandler will execute at a rate of 15fps
          }, 66)
        }
      }
      const actualResizeHandler = () => this.changeWinSize()
    }
  },
  async mounted () {
    try {
      const userInfo = await CheckLogin()
      this.isLogging = false
      userInfo && this.setUserInfo(userInfo)
      userInfo.language &&
        changeAppLanguage(userInfo.language, this.$i18n.locale)
    } catch (error) {
      window.console.log(error)
    }
  },
  created () {
    this.watchSizeChange()
  }
}
</script>
<style lang="scss">
.home-main__wrap {
  background-color: $default-page-color;
  padding: 0 4px !important;
}
.home-main__container {
  padding: 0 4px !important;
}
</style>
