import platformRouter from '@target/router/home' // 不同平台的代码模块
import statistics from './statistics'
export default [
  {
    path: '',
    component: () => import('@/components/Welcome.vue')
  },
  {
    path: 'tool/uploads',
    name: 'tool-uploads',
    component: () => import('@/views/tool/uploads.vue')
  },
  {
    path: 'dashBoard',
    name: 'dashBoard',
    alias: 'diyBoard',
    component: () => import(/* webpackChunkName: "dashBoard" */ '@/views/dashBoard/index.vue')
  },
  /**
   * 某些情况下会一直缓存，地址上有nav=1
   * 注意：通过侧边栏打开的地址会自动添加nav=1
   */
  {
    path: 'datatable',
    name: 'datatable',
    component: () => import('@/views/dataTable/Index')
  },
  /**
   * 不缓存，每次进来重新刷新
   */
  {
    path: 'datatableFresh',
    name: 'datatableFresh',
    component: () => import('@/views/dataTable/Index')
  },
  {
    path: 'diyForm',
    name: 'diyForm',
    component: () => import('@/views/diyForm/Index.vue')
  },
  {
    path: 'mapEchart',
    name: 'mapEchart',
    component: () =>
      import(/* webpackChunkName: "charts" */ '@/views/charts/map.vue')
  },
  ...statistics,
  ...platformRouter
]
