<template>
  <el-submenu :key="item.index" :index="item.index">
    <template slot="title">
      <i class="iconfont" :class="item.icon"></i>
      <span>{{ item.title }} </span>
    </template>
    <template v-for="mItem in item.items">
      <AppAsideSmartMenu
        :item="mItem"
        :key="mItem.index"
        :index="mItem.index"
        :keywork="keywork"
      ></AppAsideSmartMenu>
    </template>
  </el-submenu>
</template>
<script>
export default {
  name: 'AppAsideSubMenu',
  components: {
    AppAsideSmartMenu: () => import('@/components/AppAsideSmartMenu')
  },
  props: {
    keywork: String,
    item: {
      type: Object,
      default() {
        return {
          title: 'title',
          index: 'index',
          items: []
        }
      }
    }
  },
  mounted() {
    this.$el.dispatchEvent = () => {
      /**
       * 通过functional组件处理后，
       * 会出现this.$el===this.$parent.$el
       * 将导致poprMixins 中的 handleMouseenter 的 this.$parent.$el.dispatchEvent(new MouseEvent('mouseenter'));
       * 会重复触发直接的mouseenter，引起堆栈溢出
       * 所以这个简单粗暴地屏蔽它
       */
    }
  }
}
</script>
