<template>
  <el-row
    type="flex"
    justify="center"
    align="middle"
    :style="'height:' + height"
  >
    <div class="logo-box " :class="isCollapse ? 'small' : 'big'"></div>
  </el-row>
</template>
<script>
export default {
  props: ['isCollapse', 'height']
}
</script>
<style lang="scss" scoped>
.logo-box {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  &.big {
    background-image: url(../assets/logo.png);
  }
  &.small {
    background-image: url(../assets/logo_light.svg);
  }
}
</style>
