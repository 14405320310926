import Vue from 'vue'
import Vuex from 'vuex'
import { clearSessionStorage } from '@/utils/index'
import { Logout } from '@/apis/user'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    windowChangeCount: 0,
    userInfo: {
      name: '', // 用户名
      guid: '', // id
      phone: '', // 电话
      level: '', // 级别
      avatar: '', // 大头像
      avatar_thumb: '', // 小头像
      role: '' // 角色
    }
  },
  mutations: {
    CHANGE_WINDOW_SIZE(state) {
      state.windowChangeCount++
    },
    USER_INFO_SET(state, userInfo) {
      state.userInfo = userInfo
        ? userInfo
        : {
            name: '', // 用户名
            guid: '', // id
            phone: '', // 电话
            level: '', // 级别
            avatar: '', // 大头像
            avatar_thumb: '', // 小头像
            role: '' // 角色
          }
    }
  },
  actions: {
    /**
     * 清除原来用户数据
     * @param {*} context
     * @param {*} newUser
     */
    USER_INFO_RESET(context, userInfo) {
      if (!userInfo) clearSessionStorage()
      context.commit('USER_INFO_SET', userInfo)
    },
    USER_LOGOUT(context) {
      return Logout().then(data => {
        context.dispatch('USER_INFO_RESET')
        return data
      })
    }
  },
  modules: {}
})
