import { post } from '@/apis'

export const SetLanguage = lang => {
  lang =
    {
      'zh-cn': 1,
      en: 2,
      ja: 3
    }[lang] || '1'
  return post('/wechat/account/admin/language_setting')({
    language: lang
  })
}

export const Login = (data = { username: 'zhengxiaohuan', password: '123456' }) => post(process.env.VUE_APP_API_USER_LOGIN)(data)
