<template>
  <section id="app">
    <router-view class="xa-scrobbar"></router-view>
    <template v-if="viewImage && viewImage.isShow">
      <viewer
        :images="viewImage.imgList"
        @inited="onViewerInited"
        class="viewer"
        ref="viewer"
        :options="viewerOptions"
      >
        <img v-for="src in viewImage.imgList" :src="src" :key="src" />
      </viewer>
    </template>
  </section>
</template>

<script>
import Vue from 'vue'
import 'viewerjs/dist/viewer.css'
export default {
  name: 'app',
  components: {
    Viewer: () => import(/* webpackChunkName: "v-viewer" */ 'v-viewer/src/component.vue')
  },
  data(vm) {
    return {
      viewImage: {
        isShow: false,
        imgList: []
      },
      viewerOptions: {
        hidden() {
          vm.viewImage.isShow = false
        }
      }
    }
  },
  methods: {
    onViewerInited(viewer) {
      viewer.show()
    }
  },
  created() {
    Vue.prototype.$preViewImg = ({ isShow, imgList }) => {
      this.viewImage.isShow = isShow || true
      this.viewImage.imgList = imgList
    }
  }
}
</script>
<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  height: 100vh;
}
</style>
