<template>
  <el-aside
    class="xa-aside"
    :width="asidewidth"
    :class="isCollapse ? 'aside--collapse' : 'aside--expand'"
  >
    <el-container class="xa-container">
      <Logo
        :isCollapse="isCollapse"
        :height="layoutSize.header"
        :style="isCollapse ? '' : 'padding:0 20px'"
      />
      <AppSwitchApps
        v-show="!triggleSearch"
        :isCollapse="isCollapse"
        :apps="apps"
        :index="appsIndex"
        @change="onChangeApp"
      >
        <div
          slot="icon-right"
          v-show="!isCollapse"
          class="xa-cell"
          @click="onTriggleSearch"
        >
          <i class="el-icon-search xa-cursor"></i>
        </div>
      </AppSwitchApps>
      <div class="aside-search__input-wrap" v-show="triggleSearch">
        <el-input size="mini" placeholder="请输入内容" v-model="navKeywork">
          <i
            slot="suffix"
            class="el-input__icon el-icon-close"
            @click="onTriggleSearch"
          ></i>
        </el-input>
      </div>
      <el-main>
        <div style="position:relative">
          <AppLineLoading v-show="showLoading" />
        </div>
        <div class="xa-flex">
          <AppAsideMenu
            :keywork="navKeywork"
            :nav="nav"
            :isCollapse="isCollapse"
            :defaultActive="navDefaultActive"
            :defaultOpeneds="navDefaultOpeneds"
            @change="onChangeNav"
          />
        </div>
      </el-main>
      <AppDownLoadProgress />
      <el-footer class="foot__info">
        <AppUserInfo
          :info="userInfo"
          :collapse="isCollapse"
          @command="handCommand"
        />
      </el-footer>
      <el-footer
        class="xa-cell foot__btn"
        :height="layoutSize.footer"
        @click.native="isCollapse = !isCollapse"
      >
        <i v-if="isCollapse" class="el-icon-d-arrow-right"></i>
        <i v-else class="el-icon-d-arrow-left"></i>
      </el-footer>
    </el-container>
    <div
      class="trigger-btn"
      @click="isCollapse = !isCollapse"
      v-show="isCollapse"
    >
      <i class="el-icon-s-unfold"></i>
    </div>
  </el-aside>
</template>
<script>
/* eslint-disable vue/no-reserved-keys */
import AppUserInfo from '@/components/AppUserInfo'
import AppAsideMenu from '@/components/AppAsideMenu'
import AppSwitchApps from '@/components/AppSwitchApps'
import AppDownLoadProgress from '@/components/AppDownLoadProgress'
import Logo from '@/pk/Logo'
import { GetMainMenu, Logout } from '@/apis/user'
import { get } from '@/apis/index'
import { mapState } from 'vuex'
import { STORE_NAV_KEY } from '@/config/SessionKey'
import { STORE_COLLAPSE_KEY } from '@/config/StoreKey'
import { getDefaultAppIndex } from '@/controllers/dataFactory/aside'
export default {
  components: {
    AppUserInfo,
    AppAsideMenu,
    AppSwitchApps,
    AppDownLoadProgress,
    Logo
  },
  props: {
    layoutSize: {
      type: Object,
      default () {
        return {
          header: '40px',
          footer: '40px'
        }
      }
    }
  },
  data (vm) {
    const isCollapse = vm.$utils.getStorage(STORE_COLLAPSE_KEY) || false
    return {
      isCollapse,
      showLoading: true,
      nav: [],
      navDefaultActive: '',
      navDefaultOpeneds: [],
      apps: [],
      triggleSearch: false,
      navKeywork: '',
      appsIndex: -1,
      _CacheNavByApi: {}, // {api:[]} 缓存各个顶级菜单下的导航
      _CacheNavMap: {}, // {index:nav} 存储每个导航，使用其index值为key，便以查找
      cacheAppNavPath: {} // {app:{index:{fullPath, navActive}} 存储各个顶级菜单下当前选中的导航信息
    }
  },
  watch: {
    navKeywork (val) {
      if (!val) {
        this.navDefaultOpeneds = []
        return
      }
      const activeIndex = []
      function find (nav) {
        return !!nav.items.find(item => item.title.indexOf(val) > -1)
      }
      this.nav.forEach(item => {
        if (find(item)) {
          activeIndex.push(item.index)
        }
      })
      this.navDefaultOpeneds = activeIndex
    },
    cacheAppNavPath (val) {
      this.$utils.setSessionStorage(STORE_NAV_KEY, val)
    },
    isCollapse (val) {
      this.$utils.setStorage(STORE_COLLAPSE_KEY, val)
      if (val) {
        this.triggleSearch = false
        this.navKeywork = ''
      }
    },
    /**
     * 监听路由
     * 解决在切换不同app时候，可以恢复上次选中的侧边栏
     */
    $route (newRoute) {
      const { index } = newRoute.params
      const appsIndex = this.getAppIndexByPath(index)
      if (this.appsIndex === appsIndex) {
        this.recoveryNavActiveByUrl()
        this.recodeAppCurRouteNavPath(newRoute)
      } else {
        // 只有在导航后退才会发生链接指定的appIndex和当前appsIndex不符的情况
        this.changeApp(appsIndex).then(() => {
          this.recoveryNavActiveByUrl()
        })
      }
    }
  },
  computed: {
    ...mapState(['userInfo']),
    asidewidth () {
      return (this.isCollapse ? 64 : 200) + 'px'
    }
  },
  methods: {
    onTriggleSearch () {
      this.isCollapse = false
      this.triggleSearch = !this.triggleSearch
      this.navKeywork = ''
      if (this.triggleSearch) {
        setTimeout(() => {
          var el = document.querySelector('.aside-search__input-wrap input')
          el.focus()
        }, 0)
      }
    },
    /**
     * 设置侧边栏激活的菜单
     */
    recoveryNavActiveByUrl () {
      this._checkNavActive(this.nav)
    },
    _checkNavActive (navs) {
      navs.forEach(nav => {
        if (nav._fullPath) {
          this._checkIsCurNav(nav)
        } else if (nav.items) {
          this._checkNavActive(nav.items)
        }
      })
    },
    _checkIsCurNav (nav) {
      if (this.$route.fullPath.indexOf(nav._fullPath) === 0) {
        this.navDefaultActive = nav.index
      }
    },
    async handCommand (action) {
      if (action === 'logout') {
        const data = await Logout()
        if (data && data.data) this.$gotoUrl(data.data)
        else {
          // 如果没有给注销登录要跳转的地址，就通过请求接口来判断
          this.initAppsCfg()
        }
      }
    },
    recodeAppCurRouteNavPath (route = this.$route) {
      this._recodeAppNavPath({
        active: this.navDefaultActive,
        path: route.fullPath
      })
    },
    /**
     * 记录下当前导航激活信息
     */
    _recodeAppNavPath ({ active, path }) {
      const old = this.cacheAppNavPath || {}
      old[this.$route.params.index] = { active, path }
      this.cacheAppNavPath = {
        ...old
      }
    },
    getAppNavPath (curApp) {
      return this.cacheAppNavPath[curApp.index]
    },
    async gotoView (nav) {
      const { url, router } = nav
      if (url && url.length > 0) {
        if (url.indexOf('http') === 0) {
          this.$gotoUrl(url, true)
          return
        }
      }
      if (router) {
        if (router.query) {
          router.query.title = router.query.title || nav.title
          router.query.nav = 1
        }
        let routerMethod = 'push'
        // 如果是在基本路径 /home/statistics 则使用 replace方法
        if (this.$route.name === undefined) routerMethod = 'replace'
        return this.$router[routerMethod](router).catch(() =>
          window.console.warn('已在该页面')
        )
      }
    },
    /**
     * 选择导航菜单
     */
    onChangeNav (key) {
      this.navDefaultActive = key
      const nav = this.$data._CacheNavMap[key]
      // 记录页面地址与navDefaultActive的对应关系
      this.gotoView(nav)
      // todo: 需要跳转到真实的业务页面
    },
    /**
     * 切换app
     * 加载对应的nav
     */
    async changeApp (index) {
      if (this.showLoading) return
      this.showLoading = true
      this.appsIndex = index
      const app = this.apps[index]
      await this.fetchNavsInApp(app)
      this.showLoading = false
      return app
    },
    /**
     * @description 切换顶级菜单
     * 1 获取相应的导航菜单
     * 2 设置相应的navDefaultActive
     * 3 如果之前有在该顶级菜单下跳转过，则设置为那个路由
     */
    async onChangeApp (index, replace = true) {
      try {
        const app = await this.changeApp(index)
        const cache = this.getAppNavPath(app)
        if (cache) {
          this.navDefaultActive = cache.active
          this.$route.fullPath !== cache.path &&
            this.$router.replace(cache.path)
        } else {
          replace && this.$router.replace(app.url)
        }
        this.navKeywork = ''
        this.triggleSearch = false
      } catch (error) {
        this.$message({
          message: error.message || error.msg,
          type: 'error'
        })
      }
    },
    /**
     * 加工处理nav数据，增加唯一的关键值:index、层级深度:deep
     */
    initNavCfg (nav, baseIndex = 0, deep = 0) {
      return nav.map((item, curIndex) => {
        item.index = baseIndex + '_' + curIndex + '_' + item.title
        item._deep = deep
        if (item.router) {
          const { route } = this.$router.resolve(
            item.router,
            this.$route,
            false
          )
          item._fullPath = route.fullPath
        }
        if (item.items) {
          item.items = this.initNavCfg(item.items, item.title, deep + 1)
        } else {
          this.$data._CacheNavMap[item.index] = item
        }
        return item
      })
    },
    /**
     * 获取顶级菜单
     */
    async initAppsCfg () {
      const { page_title, t_nav } = await GetMainMenu()
      window.document.title = page_title || 'XAG'
      this.apps = t_nav
    },
    /**
     * 获取侧边栏导航信息
     */
    async fetchNavsInApp ({ api }) {
      let nav = []
      if (this.$data._CacheNavByApi[api]) {
        nav = this.$data._CacheNavByApi[api]
      } else {
        let data = await get(api)()
        nav = this.initNavCfg(data.nav)
        this.$data._CacheNavByApi[api] = nav
      }
      this.nav = nav
    },
    /**
     * @description 通过对比当前路由信息来获取当前是哪个应用
     */
    getAppIndexByPath (defaultIndex) {
      let result = 0
      for (let i = 0; i < this.apps.length; i++) {
        if (defaultIndex === this.apps[i].index) {
          result = i
          break
        }
      }
      return result
    },
    /**
     * @description 获取初始的默认顶级app
     */
    initDefaultAppIndex (defaultParams) {
      const { index } = defaultParams
      const defaultIndex = getDefaultAppIndex(index, this.apps)
      this.onChangeApp(defaultIndex, !index).then(() => {
        this.recoveryNavActiveByUrl()
        this.recodeAppCurRouteNavPath()
      })
    }
  },
  /**
   * 1 获取顶级菜单数据
   * 2 判断并设置当前是出于那个顶级菜单下：appsIndex
   * 3
   */
  async mounted () {
    await this.initAppsCfg()
    this.showLoading = false
    this.cacheAppNavPath = this.$utils.getSessionStorage(STORE_NAV_KEY) || {}
    this.initDefaultAppIndex(this.$route.params)
    window._ASIDE = this
  }
}
</script>
<style lang="scss">
.el-aside.xa-aside {
  background: $sider-bg;
  transition-property: width;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  transition-delay: 0s;
  user-select: none;
  .trigger-btn {
    display: none;
  }
  .aside__el-menu {
    border-right: none;
    &:not(.el-menu--collapse) {
      width: 200px;
    }
  }
  [class^='iconfont'] {
    display: inline-block;
    margin-right: 5px;
    width: 24px;
    font-size: 18px;
    text-align: center;
    vertical-align: middle;
    color: #fff;
    color: currentColor;
  }
  // 让高亮的内部文本居中，在菜单栏搜索中的v-html中有用
  [role^='highlight'] * {
    vertical-align: baseline;
  }
  .el-main {
    padding: 0;
    &::-webkit-scrollbar {
      width: 1px;
      height: 1px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 0px;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: rgba(0, 0, 0, 0.2);
      background: #366eb4;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
      background: #366eb4;
    }
  }
  .el-footer {
    padding: 0;
    &.foot__info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;
    }
    &.foot__btn {
      $color: #324157;
      padding: 0px;
      color: #fff;
      background: $color;
      justify-content: center;
      cursor: pointer;
      &:hover {
        background: darken($color, 10%);
      }
    }
  }
  $menu-item-height: 44px;
  .el-menu-item,
  .el-submenu__title {
    height: $menu-item-height;
    line-height: $menu-item-height;
  }
  .el-submenu .el-menu-item {
    height: $menu-item-height;
    min-width: 160px;
  }
}
@media (max-width: 640px) {
  .el-aside.xa-aside {
    position: fixed;
    left: 0px;
    top: 0;
    bottom: 0;
    z-index: 999;
    &.aside--collapse {
      left: -64px;
      .trigger-btn {
        display: block;
        position: fixed;
        padding: 8px 0;
        top: 50%;
        left: 0;
        z-index: 9999;
        background-color: $default-page-color;
      }
    }
  }
}
.aside-search {
  &__input-wrap {
    padding: 8px 16px;
  }
}
</style>
