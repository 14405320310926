import Vue from 'vue'
import Element from 'element-ui'
import '../element-variables.scss'
import enLocale from 'element-ui/lib/locale/lang/en'
import jaLocale from 'element-ui/lib/locale/lang/ja'
const LOCALE = {
  en: enLocale,
  ja: jaLocale
}
function getLocaleByPath(locale, paths) {
  for (let i = 0; i < paths.length; i++) {
    locale = locale[paths[i]]
  }
  return locale
}
Vue.use(Element, {
  // eslint-disable-next-line no-unused-vars
  i18n: function(path, options) {
    const locale = LOCALE[Vue.config.lang]
    let content = locale && getLocaleByPath(locale, path.split('.'))
    if (options && content) {
      Object.keys(options).forEach(
        key => (content = content.replace(`{${key}}`, options[key]))
      )
    }
    return content
  }
})
